import * as yup from 'yup'
import {
  validationInvalidFormatKey,
  validationMaxLengthKey,
  validationMinLengthKey,
  validationNoLeadingSpacesKey,
  validationRequiredKey,
} from 'src/shared/constants'

export const cardHolderValidationSchema = yup
  .string()
  .required(validationRequiredKey)
  .min(2, validationMinLengthKey)
  .max(26, validationMaxLengthKey)
  .matches(/^[a-zA-Z -]*$/, validationInvalidFormatKey)
  .test({
    message: validationNoLeadingSpacesKey,
    test: function (value) {
      if (!value) return true
      const words = value.split(' ')
      const isValid = words.every((x) =>
        x.length > 2 ? /^[a-z].*[a-z]$/i.test(x) : /^[a-z]{1,2}$/i.test(x),
      )
      return isValid
    },
  })
