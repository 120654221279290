import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
// local libs
import { getLocalKeyData } from './helpers/getLocalKeyData'

type Attributes = {
  [key: string]: string | number
}

export type TranslateFunction = (
  localKey: string | undefined | null,
  attributes?: Attributes | undefined,
) => string

export const useTranslate = () => {
  const { t } = useTranslation()

  const translate: TranslateFunction = useCallback(
    (localKey, attributes = {}) => {
      if (!localKey) return ''

      const { key } = getLocalKeyData(localKey)
      return t(key, { ...attributes })
    },
    [t],
  )

  return { translate }
}
