import * as yup from 'yup'
import {
  validationInvalidFormatKey,
  validationMinLengthKey,
  validationRequiredKey,
} from 'src/shared/constants'

export const cardNumberValidationSchema = yup
  .string()
  .transform((value: string) => value.replaceAll(' ', ''))
  .required(validationRequiredKey)
  .min(13, validationMinLengthKey)
  .matches(
    /^(4[0-9]{15}|5[1-5][0-9]{14}|2[0-9]{15}$)$/,
    validationInvalidFormatKey,
  )

/*
### Разбор работы регулярного выражения:
- **`^`**: Начинаем проверку с начала строки.
- **`4[0-9]{12,18}`**: Соответствует номеру карты, который начинается с цифры 4 и за которым следует от 12 до 18 цифр. Это характерно для карт Visa.
- **`|`**: Или
- **`5[1-5][0-9]{11,17}`**: Соответствует номеру карты, который начинается с цифры 5, за которой следует цифра от 1 до 5, и затем ещё от 11 до 17 цифр. Это характерно для карт MasterCard.
- **`|`**: Или
- **`2[0-9]{12,18}`**: Соответствует номеру карты Mir (начинающихся с 22).
- **`$`**: Проверка продолжается до конца строки.

### Применение:
Это регулярное выражение было бы полезно для валидации различных номеров банковских карт (в основном, Visa и MasterCard).

### Примеры соответствия:
1. **`4111111111111111`**: Соответствует (Visa).
2. **`5111111111111111`**: Соответствует (MasterCard).
3. **`2221000000000009`**: Соответствует (Mir).

### Примеры несоответствия:
1. **`1234567890123456`**: Не соответствует.
2. **`6789012345678901`**: Не соответствует.
3. **`hello_kitty_hell`**: Не соответствует (так как содержит нецифровые символы).

Это регулярное выражение эффективно проверяет основные типы номеров банковских карт.
*/
