import * as yup from 'yup'
import {
  validationMaxLengthKey,
  validationMinLengthKey,
  validationRequiredKey,
} from 'src/shared/constants'

export const phoneValidationSchema = yup
  .string()
  .required(validationRequiredKey)
  .min(7, validationMinLengthKey)
  .max(15, validationMaxLengthKey)
